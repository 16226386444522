<template>
  <b-modal
    id="user-information"
    modal-class="user-information-modal"
    centered
    :hide-footer=true
    size="md"
  >
    <template v-slot:modal-header="{ close }">
        <h5>{{ user.getFirstName | defaultValue }} {{ user.getLastName | defaultValue }}</h5>
        <span class="close" @click="close()">
          <img src="@/components/Modal/close-modal.svg">
        </span>
    </template>

    <b-media class="my-2" v-if="extendedUser.hasContactEmail">
      <template v-slot:aside>
        <img src="../../assets/icons/icon_envelope.svg" width="16" alt="icon-envelope" class="mt-1">
      </template>
      <p class="media-body-title my-0">Email</p>
      <span class="media-body-text text-sm">
        <a :href="'mailto:' + extendedUser.getContactEmail">{{ extendedUser.getContactEmail }}</a>
      </span>
    </b-media>
    <b-media class="my-2" v-if="extendedUser.hasPhoneNumber">
      <template v-slot:aside>
        <img src="../../assets/icons/icon_phone.svg" width="16" alt="icon-phone" class="mt-1">
      </template>
      <p class="media-body-title my-0">Teléfono</p>
      <span class="media-body-text text-sm">
        <a :href="'tel:' + extendedUser.getPrefix + extendedUser.getPhoneNumber">
          +{{ getPrefix | defaultValue }}
          {{ extendedUser.getPhoneNumber | defaultValue }}
        </a>
      </span>
    </b-media>
    <b-media class="my-2" v-if="extendedUser.hasLinkedInProfile">
      <template v-slot:aside>
        <img src="../../assets/icons/icon_linkedin.svg" width="16" alt="icon-linkedin" class="mt-1">
      </template>
      <p class="media-body-title my-0">Linkedin</p>
      <span class="media-body-text text-sm"><a :href="extendedUser.getlinkedInProfile"> {{ extendedUser.getlinkedInProfile | defaultValue }}</a></span>
    </b-media>
  </b-modal>
</template>

<script>
export default {
  name: 'UserInformationModal',
  props: {
    user: Object,
    extendedUser: Object
  },
  computed: {
    getPrefix () {
      return this.extendedUser.getPrefix.replace('+', '')
    }
  }
}
</script>

<style lang="less">
  @import "userInformationModal.less";
</style>
