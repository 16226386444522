<template>
  <private-image-card
    :user="user"
    :extendedUser="extendedUser"
    :showRoles="showRoles"
    modalId="edit-profile"
    :edit-icon="editable"
    eventName="onClickEditProfile"
    @onClickEditProfile="onClickEditProfile"
  />
</template>

<script>
import PrivateImageCard from '../Card/PrivateImageCard'
export default {
  name: 'ProfileCard',
  props: {
    user: Object,
    extendedUser: Object,
    editable: {
      type: Boolean,
      default: true
    },
    showRoles: {
      type: Boolean,
      default: true
    }
  },
  components: { PrivateImageCard },
  methods: {
    onClickEditProfile () {
      this.$bvModal.show('edit-profile')
    }
  }
}
</script>

<style scoped>

</style>
