<template>
  <b-card no-body class="private-image-card">
    <b-card-header>
      <img v-if="extendedUser.hasCover" :src="baseUrl + extendedUser.getCover.path"
        alt="cover-image" class="h-100 w-100 mt-0" height="176" />
      <img v-else src="../../assets/img/profile-background.png"
        alt="cover-image" class="h-100 w-100 mt-0" height="176" />
      <span v-if="editIcon" class="edit-card-info-image" @click="$emit(eventName)"></span>
    </b-card-header>
    <b-card-body>
      <div class="avatar-container d-flex align-items-end">
        <img v-if="extendedUser.hasAvatar" :src="baseUrl + extendedUser.getAvatar.path" alt="avatar" class="user-avatar img-thumbnail img-fluid"/>
        <img v-else src="/img/default-user-avatar.svg" alt="avatar" class="user-avatar img-thumbnail img-fluid"/>
        <span class="user-nick ml-3">{{ user.getNickname }}</span>
      </div>
      <div class="user-info-container mt-3">
        <b-card-title>
          {{ user.getFirstName | defaultValue }} {{ user.getLastName | defaultValue }}
        </b-card-title>
        <div class="skill-badge-list mb-3" v-if="showRoles">
          <skill-badge :name="role.name" v-for="role in user.getRoles" :key="role.id"></skill-badge>
        </div>
        <p v-if="extendedUser.hasLocation">{{ extendedUser.getCompleteLocation }}</p>
        <b-link
          v-b-modal.user-information
          class="user-info-modal-button">
            Información de contacto
        </b-link>
        <user-information-modal
          :user="user"
          :extendedUser="extendedUser"
        />
      </div>
    </b-card-body>
    <modal-component
      :id="modalId"
      title="Edita tus datos"
      size="lg"
      ok-title="Guardar cambios"
      :noPadding="true"
      @confirmed="onConfirmedEditProfileCard"
    >
      <div class="edit-cover-image-container">
        <img
          v-if="!editCoverImage && !extendedUser.hasCover"
          src="../../assets/img/profile-background.png"
          alt="cover-image"
          class="cover-edit w-100" style="height: 176px">
        <img
          v-else-if="!editCoverImage && extendedUser.hasCover"
          :src="baseUrl + extendedUser.getCover.path"
          alt="cover-image"
          class="cover-edit w-100"
          style="height: 176px">
        <span
          @click="editCoverImage = !editCoverImage"
          class="change-cover-image edit-card-info-image"
          v-if="!editCoverImage"></span>
        <image-upload
          @onCancelUploadFile="editCoverImage = !editCoverImage"
          @onChangeUploadFile="editCoverImage = !editCoverImage"
          :coverImgUrl="getCoverUrl"
          v-if="editCoverImage"
        ></image-upload>
      </div>

      <div
        class="edit-avatar-image-container"
        :class="{'w-100': editAvatarImage, 'mt-0': editCoverImage }"
      >
        <img
          v-if="!editAvatarImage && extendedUser.hasAvatar"
          :src="baseUrl + extendedUser.getAvatar.path"
          alt="avatarmage-i"
          class="avatar-edit"/>
        <img
          v-else-if="!editAvatarImage"
          src="/img/default-user-avatar.svg"
          alt="avatar-image"
          class="avatar-edit"/>
        <span
          @click="editAvatarImage = !editAvatarImage"
          class="change-avatar-image edit-avatar-info-image"
          v-if="!editAvatarImage"></span>
        <avatar-image-upload
          @onCancelUploadAvatarFile="editAvatarImage = !editAvatarImage"
          @onChangeUploadAvatarFile="editAvatarImage = !editAvatarImage"
          :avatarImgUrl="getAvatarUrl"
          v-if="editAvatarImage"></avatar-image-upload>
      </div>
      <validation-observer ref="editUserInformationForm" v-slot="{ handleSubmit }">
      <b-form
        autocomplete="off"
        novalidate
        @submit.stop.prevent="handleSubmit()"
        class="edit-profesional-form"
      >
        <b-row>
          <b-col cols="12" sm="6">
            <validation-provider
              vid="name"
              rules="required"
              name="nombre"
              v-slot="validationContext"
            >
              <b-form-group id="name" label-for="input-name">
                <b-form-input
                  id="name"
                  name="input-name"
                  v-model="editableUser.firstName"
                  placeholder="Nombre"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6">
            <validation-provider
              vid="lastName"
              rules="required"
              name="apellidos"
              v-slot="validationContext"
            >
              <b-form-group id="last-name" label-for="input-last-name">
                <b-form-input
                  id="last-name"
                  name="input-last-name"
                  v-model="editableUser.lastName"
                  placeholder="Apellidos"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              vid="contactEmail"
              name="email de contacto"
              rules="email"
              v-slot="validationContext"
            >
              <b-form-group id="contact-email" label-for="input-contact-email">
                <b-form-input
                  id="contact-email"
                  name="input-contact-email"
                  v-model="editableExtendedUser.contactEmail"
                  placeholder="Email de contacto"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <validation-provider
              vid="prefix"
              :rules="{ prefix: true }"
              name="prefijo telefónico"
              v-slot="validationContext"
            >
            <b-form-group id="phone-prefix" label-for="input-phone-prefix">
              <b-form-input
                maxlength="4"
                onkeydown="return event.keyCode !== 69"
                id="phone-prefix"
                name="input-phone-prefix"
                v-model="editableExtendedUser.phoneNumber.prefix"
                :state="getValidationState(validationContext)"
                placeholder="Prefijo"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              vid="number"
              name="número de teléfono"
              v-slot="validationContext"
            >
            <b-form-group id="phone-number" label-for="input-phone-number">
              <b-form-input
                pattern="[0-9]"
                onkeydown="return event.keyCode !== 69"
                type="number"
                id="phone-number"
                name="input-phone-number"
                v-model="editableExtendedUser.phoneNumber.number"
                :state="getValidationState(validationContext)"
                placeholder="Teléfono"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              vid="address"
              name="dirección"
              v-slot="validationContext"
            >
              <b-form-group id="address" label-for="input-address">
                <b-form-input
                  id="address"
                  name="input-address"
                  v-model="editableExtendedUser.address"
                  placeholder="Dirección (Calle y número)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="4">
            <b-form-group id="postalCode" label-for="input-postalCode">
              <b-form-input
                id="postalCode"
                type="number"
                name="input-postalCode"
                v-model="editableExtendedUser.postalCode"
                placeholder="Código postal"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="8">
            <GooglePlaces
              :value="value"
              @selected="onChangeGoogleData"
              @keypress="onKeyPressGoogle"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              vid="linkedInProfile"
              name="perfil linkedin"
              :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
              v-slot="validationContext"
            >
              <b-form-group id="linkedin" label-for="input-linkedin">
                <b-form-input
                  id="linkedin"
                  name="input-linkedin"
                  v-model="editableExtendedUser.linkedInProfile"
                  :state="getValidationState(validationContext)"
                  placeholder="Perfil Linkedin (URL)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              vid="nickname"
              name="Nick / nombre artístico"
              v-slot="validationContext"
            >
              <b-form-group id="nickname" label-for="input-nickname">
                <b-form-input
                  id="nickname"
                  name="input-nickname"
                  v-model="editableExtendedUser.nickname"
                  :state="getValidationState(validationContext)"
                  placeholder="Nick / nombre artístico"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>
    </modal-component>
  </b-card>
</template>
<script>
import SkillBadge from '../SkillBadge/SkillBadge'
import UserInformationModal from '../Modal/UserInformationModal'
import ModalComponent from '../Modal/ModalComponent'
import EditableUserFactory from '@/domain/services/EditableUserFactory'
import EditableDashboardUserTechnicianFactory from '@/domain/services/EditableDashboardUserTechnicianFactory'
import LocationFactory from '@/domain/services/LocationFactory'
import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'
import ImageUpload from '../ImageUpload/ImageUpload'
import AvatarImageUpload from '../ImageUpload/AvatarImageUpload'
import GooglePlaces from '../Places/GooglePlaces.vue'

Vue.use(VueAxios, axios)
export default {
  name: 'PrivateImageCard',
  components: {
    ModalComponent,
    UserInformationModal,
    SkillBadge,
    ImageUpload,
    AvatarImageUpload,
    GooglePlaces
  },
  data () {
    return {
      editableUser: {},
      editableExtendedUser: {},
      value: null,
      suggestion: undefined,
      editCoverImage: false,
      editAvatarImage: false,
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  props: {
    user: Object,
    extendedUser: Object,
    eventName: String,
    editIcon: {
      type: Boolean,
      default: true
    },
    modalId: String,
    showRoles: {
      type: Boolean,
      default: true
    }
  },
  created: function () {
    this.editableUser = EditableUserFactory.getEditableUser(this.user)
    this.editableExtendedUser = EditableDashboardUserTechnicianFactory
      .getEditableDashboardUserTechnician(this.extendedUser)
  },
  mounted () {
    if (this.extendedUser.hasLocation) {
      this.setGoogleValue()
    }
  },
  methods: {
    onKeyPressGoogle () {
      this.value = ''
    },
    async onConfirmedEditProfileCard () {
      const valid = await this.$refs.editUserInformationForm.validate()
      if (!valid || this.value === '') {
        return
      }

      this.lockScreen()
      const data = this.editableUser
      data.technician = this.editableExtendedUser
      this.$store.dispatch('updateUserTechnician', data)
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide(this.modalId)
            if (this.extendedUser.hasLocation) {
              this.setGoogleValue()
            }
            this.$store.dispatch('showConfirmationModal')
          })
        })
        .catch(error => {
          const responseData = error.response.data
          const responseErrors = this.createFormFieldErrors(responseData)
          this.$refs.editUserInformationForm.setErrors(responseErrors)
          this.unlockScreen()
        })
    },
    onChangeGoogleData (evt) {
      this.suggestion = evt
      this.editableExtendedUser.updateLocation(LocationFactory.getLocationByGoogle(evt))
      this.setGoogleValue()
    },
    setGoogleValue () {
      this.value = this.editableExtendedUser.location.name + ', ' +
        this.editableExtendedUser.location.administrative + ', ' +
        this.editableExtendedUser.location.country
    }
  },
  computed: {
    getCoverUrl () {
      if (this.extendedUser.hasCover) {
        return this.baseUrl + this.extendedUser.getCover.path
      }

      return '/img/profile-background.png'
    },
    getAvatarUrl () {
      if (this.extendedUser.hasAvatar) {
        return this.baseUrl + this.extendedUser.getAvatar.path
      }

      return '/img/default-user-avatar.svg'
    }
  }
}
</script>

<style lang="less">
  @import 'privateImageCard.less';

  .change-cover-image {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .edit-card-info-image {
    background-image: url("../../assets/icons/edit_card.svg");
    width: 32px;
    height: 32px;
    display: block;
    cursor: pointer;
  }

  .edit-profesional-form {
    padding: 1rem;
  }

  .edit-avatar-image-container {
    padding-left: 1rem;
    margin-top: -56px;
    position: relative;
    width: 128px;

    .avatar-edit {
      height: 112px;
      width: 112px;
      border: 3px solid @color_white;
      border-radius: 4px;
    }

    .change-avatar-image {
      top: .25rem;
      right: .25rem;
    }

    .edit-avatar-info-image {
      background-image: url("../../assets/icons/edit_card.svg");
      width: 32px;
      height: 32px;
      display: block;
      cursor: pointer;
      position: absolute;
    }
  }
</style>
