import EditableUserTechnician from '../models/EditableUserTechnician'

class EditableDashboardUserTechnicianFactory {
  getEditableDashboardUserTechnician (UserData) {
    const userTechnician = {
      address: UserData.address,
      postalCode: UserData.postalCode,
      phoneNumber: UserData.phoneNumber,
      nickname: UserData.nickname,
      linkedInProfile: UserData.linkedInProfile,
      contactEmail: UserData.contactEmail,
      location: UserData.location
    }
    return new EditableUserTechnician(userTechnician)
  }
}

export default new EditableDashboardUserTechnicianFactory()
